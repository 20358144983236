import { FieldAttribute } from '@app/additional-fields/interfaces/field-attribute';
import { DeliveryType } from '@app/orders/enums/delivery-type';
import { OrderOfferDocument } from '@app/shared-widgets/interfaces/order-offer-document.interface';
import {
    FinancialData,
    RealtimeFinancialData,
} from '@core/interfaces/financial-data.interface';
import { HasMany } from '@core/relations/has-many';
import { HasOne } from '@core/relations/has-one';
import { Audit } from './audit';
import { Company } from './company';
import { InsuranceRate } from './insurance-rate';
import { JobBikeProcessFlow } from './job-bike-process-flow.interface';
import { Leasing } from './leasing';
import { Model } from './model';
import { Offer } from './offer';
import { OfferAccessory } from './offer-accessory';
import { Product } from './product';
import { ProductCategory } from './product-category';
import { ServiceRate } from './service-rate';
import { Status } from './status';
import { Supplier } from './supplier';
import { User } from './user';
import { Vat } from './vat';

export enum OrderStatus {
    Ready = 12,
    Completed = 13,
    Open = 14,
    Broken = 33,
    Sent = 34,
}

@HasOne(Company, 'company')
@HasOne(Offer, 'offer')
@HasMany(Audit, 'audits')
@HasOne(ServiceRate, 'serviceRate')
@HasOne(InsuranceRate, 'insuranceRate')
@HasOne(User, 'user')
export class Order extends Model implements Leasing {
    public company: Company;
    public product: Product;
    public user: User;
    public supplierCode: string;
    public supplierName: string;
    public supplierStreet: string;
    public supplierPostalCode: string;
    public supplierCity: string;
    public supplierUserID: number;
    public companyUserID: number;
    public senderName: string;
    public costCentre: string;
    public employeeNumber: string;
    public employeeCostCenter: string;
    public employeeFrontName: string;
    public employeeName: string;
    public employeeCode: string;
    public employeeSalutation: string;
    public employeeStreet: string;
    public employeePostalCode: string;
    public employeeCity: string;
    public employeeEmail: string;
    public employeePhone: string;
    public frame_number: string;
    public productCategory: ProductCategory;
    public productBrand: string;
    public productModel: string;
    public productColor: string;
    public productSize: string;
    public taxRate: number;
    public vat: Vat;
    public productListPrice: number;
    public productDiscountedPrice: number;
    public productDiscount: number;
    public accessoriesPrice: number;
    public accessoriesDiscountedPrice: number;
    public service_rate_amount: string;
    public service_rate_name: string;
    public insurance_rate_name: string;
    public accessories: OfferAccessory[];
    public agreedPurchasePrice: number;
    public apartmentWorkplaceDistance: number;
    public homeOfficeTravelTax: number;
    public leasing_rate: number;
    public leasing_rate_amount: string;
    public serviceRate?: ServiceRate;
    public service_rate: number;
    public insuranceRate?: InsuranceRate;
    public insurance_rate: number;
    public insurance_rate_amount: string;
    public leasing_period: number;
    public invoice_file: string;
    public invoice_type: string;
    public number: string;
    public automaticCreditNote: boolean;
    public offer: Offer;
    public status: Status;
    public date: string;
    public calculated_residual_value: number;
    public product_size: string;
    public notes: string;
    public pickup_code: string;
    public reception_code: string;
    public address: string;
    public city: any;
    public zip: string;
    public accepted_at: string;
    public deliveryDate: string;
    public deliveryPlanned: string;
    public delivered: string;
    public customDeliveryCompanyText: string;
    public canPickup: boolean;
    public parentSupplier: Supplier;
    public supplier_id: number;
    public audits: Audit[];
    public cancelled_at: string;
    public cancellationReason: string;
    public cancellationKey: string;
    public alternativeOfferExists: boolean;
    public canBeCancelled: boolean;
    public subSupplierId: number;
    public govecsDeliveryType: DeliveryType;
    public realtime_financial_data: RealtimeFinancialData;
    public financial_data: FinancialData;
    public display_financial_data: RealtimeFinancialData;
    public no_cancel_credit_financial_data: RealtimeFinancialData;
    public single_leasing_document: any;
    public takeover_document: OrderOfferDocument;
    public invoice_document: any;
    public credit_note_document: any;
    public sepa_document: any;
    public offerNumber: string;
    public companyName: string;
    public contract_document: OrderOfferDocument;
    public signed_contract_document: OrderOfferDocument;
    public employeeAttributes: FieldAttribute[] = [];
    public reference: string;
    public jobBikeProcessFlow?: JobBikeProcessFlow[];

    public getAccessories(): OfferAccessory[] {
        return this.accessories;
    }
}
